<!-- Вывод списка -->

<template>
    <div id="list" v-if="list">
        <div id="header" v-if="showHeader && list.length > 0">
            <div>№</div>
            <div>ID</div>
            <div>Название</div>
            <div></div>
        </div>
        <div v-if="showEmptyData && list.length == 0" id="empty-list">Список пуст</div>
        <div
            v-for="(row, index) in list"
            :key="index"
            class="row"
            >
            <div>{{ row.number }}</div>
            <div>{{ row.unique_number }}</div>
            <div>{{ row.service_name }}</div>
            <div>
                <div class="icon-chat" @click.stop="openChat(row)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TableList',
        props: {
                showHeader: { type: Boolean, default: false },
                showEmptyData: { type: Boolean, default: true },
            },
        data() {
            return {
                list: this.listData,
            }
        },
        created() {
        },
        methods: {
            update(data) {
                this.list = data;
            },
            openChat(item) {
                
                if(window.FMCHAT) {
                    window.FMCHAT.openChat(item.uuid, item.medcenter_uuid, item.doctor_uuid);
                }
            }
        }
    };
</script>

<style scoped>
    #header,
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 100px;
        text-decoration: none;
        height: 45px;
    }
    .row > div {
        display: flex;
        align-items: center;    
    }
    #header > div {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #636363;
        display: flex;
        align-items: center; 
    }
    
    #empty-list {
        font-size: 12px;
        line-height: 15px;  
        color: #808080;
        margin-left: 20px;
    }
    .icon-chat {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(/images/icon-chat-2.svg) no-repeat center;
        cursor: pointer;
    }
</style>