export function getChart() {
    if (Boolean(process.env.VUE_APP_CHAT_LOAD) == true) {
        window.FMCHAT_SERVER_HOST = process.env.VUE_APP_CHAT_SERVER_HOST;
        window.FMCHAT_API_SERVER = process.env.VUE_APP_CHAT_API_SERVER;
        window.FMCHAT_SERVER_PORT = process.env.VUE_APP_CHAT_SERVER_PORT;

        var _fmchata = document.createElement('script');
        _fmchata.type = 'text/javascript';
        _fmchata.async = true;
        _fmchata.src = 'https://' + window.FMCHAT_SERVER_HOST + ':' + window.FMCHAT_SERVER_PORT + '/js/init_famedchat.js';

        var _fmchata_s = document.getElementsByTagName('script')[0];
        _fmchata_s.parentNode.insertBefore(_fmchata, _fmchata_s);

        _fmchata.onload = () => {
            if (!localStorage.token || localStorage.token.length < 50) {
                return;
            }

            if (typeof famedChatInit === 'function')
                window["famedChatInit"](localStorage.token, 'medcenter');
        };
    }
}