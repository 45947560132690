<!-- Вход в личный кабинет. Формы авторизации -->

<template>
    <div id="login_root">
        <div id="header">
            <router-link to="/"
                         class="header-logo"
                             :class="{
                                'header-logo-rus': $i18n.locale == 'ru',
                                'header-logo-eng': $i18n.locale != 'ru'
                             }"
                         >
            </router-link>
        </div>
        
        <div id="content">
            
            <div class="form" v-if="isPage(FORM_LOGIN)">
                <div class="form-title">{{ $t('text_3') }}</div>
                <input 
                    type="email"
                    name="email"
                    ref="loginFormEmailInput" 
                    placeholder="Email"
                    class="form-input" 
                    v-on:keyup.enter="sendData" 
                    v-model="form1.email"
                    @input="formLoginOnChange()"
                    :class="{'form-input-disabled': form1.errorForm_1}"
                    />
                
                <div class="form-password-row">
                    <input 
                        :type="(form1.showPassword) ? 'text':'password'" 
                        ref="loginFormPasswordInput" 
                        :placeholder="$t('password')"
                        class="form-input" 
                        v-on:keyup.enter="sendData" 
                        v-model="form1.passwordLogin"
                        @input="formLoginOnChange()"
                        :class="{'form-input-disabled': form1.errorForm_2}"
                        />
                    <div class="form-password-row-eye" :class="{
                        'form-password-row-eye-show': ! form1.showPassword,
                        'form-password-row-eye-hide': form1.showPassword
                    }"
                    :title=" (form1.showPassword) ? $t('hide_password_text') : $t('show_password_text')"
                    @click="form1.showPassword = ! form1.showPassword"></div>
                </div>
                    
                <button class="form-1-btn form-btn" v-on:click="sendData" :disabled="form1.btnDisabled">
                    <img v-if="form1.showLoadingIcon" src="/images/loading_ajax.svg" />
                    <span :class="{'addPaddingMargin': form1.showLoadingIcon }">{{ $t('login') }}</span>
                </button>
                <div class="form-footer-text">
                    <span v-html="$t('text_4')"></span>
                    <a :href="'/privacy-policy-'+($i18n.locale == 'ru' ? 'ru' : 'en') +'.html'" target="_blank">{{ $t('text_5') }}</a>.
                </div>
                <!--<div id="form-footer">
                    <span class="noselect" @click="showPage(FORGOT_PASSWORD)">{{ $t('forgot_your_password') }}</span>
                </div>-->
            </div>
            
            <div id="form-forgot-password" class="form" v-if="isPage(FORGOT_PASSWORD)">
                <div class="form-title">{{ $t('password_recovery') }}</div>
                <div class="form-title2" v-html="$t('text_6')"></div>
                <input 
                    type="email"
                    name="email"
                    ref="loginForm2Email" 
                    placeholder="Email"
                    class="form-input"
                    v-model="form2.email"
                    @input="formForgotPasswordOnChange()"
                    :class="{'form-input-disabled': form2.error}"
                    />
                    
                <button class="form-1-btn form-btn" @click="sendDataForgotPassword" :disabled="form2.btnDisabled">
                    <img v-if="form2.showLoadingIcon" src="/images/loading_ajax.svg" />
                    <span :class="{'addPaddingMargin': form2.showLoadingIcon }">{{ $t('password_recovery_2') }}</span>
                </button>
                <div id="form-forgot-password-footer">
                    <span @click="showPage(FORM_LOGIN)">{{ $t('cancel_1') }}</span>
                </div>
            </div>
            
            <div id="form-forgot-password-info" class="form" v-if="isPage(FORGOT_PASSWORD_INFO)">
                <div class="form-title">{{ $t('password_recovery') }}</div>
                <div class="form-title2">{{ $t('text_7') }} <u>{{ form2.email }}</u></div>
                
                <button class="form-btn" @click="showPage(FORM_LOGIN)">
                    <span>Ок</span>
                </button>
            </div>
            
        </div>
        
        <div id="login-footer">
            © {{ $t('footer_1') + YEAR}}
        </div>

    </div>
</template>

<script>
    import { validateEmail } from '../global.js'
    
    export default {
        name: 'Login',
        mounted() {
            this.init();
        },
        data() {
            return {
                show_page: 0,
                
                form1: {
                    //  Поле ввода email
                    email: '',
                    //  Поле ввода пинкода
                    passwordLogin: '',
                    // Ошибка ввода email и password
                    errorForm_1: false,
                    errorForm_2: false,

                    // Форма 1. Управление кнопкой (иконка, disable/enable)
                    showLoadingIcon: false,
                    btnDisabled: false,

                    showPassword: false,
                },
                
                form2: {
                    email: '',
                    error: false,
                    btnDisabled: false,
                    showLoadingIcon: false,
                },

                YEAR: (new Date()).getFullYear()
            };
        },
        watch: {
            show_page(id) {
                if(id == this.FORM_LOGIN) {
                    document.title = this.$t('title_3');
                }
                else {
                    document.title = this.$t('title_4');
                }
            },
        },
        created() {
            //--- PAGES --------------------------------------------------------
            this.FORM_LOGIN            = 0;
            this.FORGOT_PASSWORD       = 1;
            this.FORGOT_PASSWORD_INFO  = 2;
            //------------------------------------------------------------------
            
            document.title = this.$t('title_3');
        },
        methods: {
            showPage(id) {
                this.show_page = id;
                localStorage.myPageOpenedPage = id;
            },
            isPage(id) {
                return this.show_page == id;
            },
            init() {
                setTimeout(()=>{
                    if(this.$refs.loginFormEmailInput)
                        this.$refs.loginFormEmailInput.focus();
                }, 200);
            },
            formForgotPasswordOnChange() {
                this.form2.error = false;
            },
            sendDataForgotPassword() {
                this.form2.error = false;
                
                //console.log( this.form2.email, validateEmail( this.form2.email ));
                
                if( ! validateEmail( this.form2.email ) ) {
                    this.form2.error = true;
                    return;
                }
                
                this.form2.btnDisabled = true;
                this.form2.showLoadingIcon = true;
                
                // forgot password
                this.$postRequest(
                    this.$config.urlPasswordSendEmail + 'auditor'
                    , { email: this.form2.email}
                   )
                .then(() => {

                    this.form2.btnDisabled = false;
                    this.form2.showLoadingIcon = false;

                    this.showPage(this.FORGOT_PASSWORD_INFO);
                    
                })
                .catch(() => {
                    this.form2.btnDisabled = false;
                    this.form2.showLoadingIcon = false;
                    
                    this.form2.error = true;
                });
            },
            formLoginOnChange () {
                this.form1.errorForm_1 = false;
                this.form1.errorForm_2 = false;
            },
            sendData() {
                
                this.form1.errorForm_1 = false;
                this.form1.errorForm_2 = false;
                   
                if( ! validateEmail( this.form1.email ) ) {
                    this.form1.errorForm_1 = true;
                    return;
                }
                   
                if( this.form1.passwordLogin.trim().length < 3 ) {
                    this.form1.errorForm_2 = true;
                    return;
                }
                   
                this.form1.btnDisabled = true;
                this.form1.showLoadingIcon = true;

                // login
                this.$postRequest(
                    this.$config.urlLogin
                    , {email: this.form1.email, password: this.form1.passwordLogin}
                   )
                .then((data) => {

                    this.form1.btnDisabled = false;
                    this.form1.showLoadingIcon = false;

                    localStorage.token = data.data.token;
                    localStorage.SpesialictName = data.data.user.name;
                    this.$router.push('/');
                    
                    
                })
                .catch(() => {
                    this.form1.btnDisabled = false;
                    this.form1.showLoadingIcon = false;
                    
                    this.form1.errorForm_1 = true;
                    this.form1.errorForm_2 = true;
                });
            },
        }
    }
    
</script>

<style scoped>
#login_root {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#header {
    background: #FFF;
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    padding-left: 42px;
    border-bottom: 1px solid #D1DCE5;
}
#login-footer {
    padding: 10px;
    font-size: 12px;
    text-align: center;
}
#content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
#header-logo-c {
    display: inline-flex;
    flex-direction: column;
}
.header-logo {
    height: 25px;
    cursor: pointer;
    width: 122px;
    display: inline-block;
}
.header-logo-rus {
    background: url(/images/logo.svg) no-repeat;
}
.header-logo-eng {
    background: url(/images/logo_eng.svg) no-repeat;
}
.form {
    background: #F2F9FF;
    border-radius: 20px;
    padding: 35px 30px 21px 30px;
    width: 490px;
    min-height: 370px;
    margin-top: 50px;
}
.form-title {
    font-family: MontserratBold, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #233B4C;
    margin-bottom: 29px;
    text-align: center;
}
.form-input {
    background: #fff;
    width: 100%;
    border: 1px solid #D1DCE5;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #000000;
    padding: 10px 21px;
    height: 45px;
    margin-bottom: 15px;
}
.form-input-disabled {
    border: 1px solid #EB5757;
    color: #EB5757;
}
.form-btn {
    background: #2D87E2;
    border-radius: 80px;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    border: none;
    width: 100%;
    height: 50px;
    cursor: pointer;
    overflow: hidden;
}
.form-btn > img {
    height: 24px;
    display: inline-block;
    margin-right: 10px;
}
.form-btn:hover {
    background: #317bc8;
}
.form-btn:disabled {
    background: #81AFDE;
}
.form-1-btn {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-footer-text {
    margin-top: 17px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #233B4C;
}
.form-footer-text > a {
    color: #2C8AE0;
}
#form-footer {
    text-align: center;
    margin-top: 12px;
}
#form-footer > span {
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2C8AE0;
    border-bottom: 1px dotted;
}

#login-form-wait-resend-sms {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#login-form-wait-resend-sms > img {
    height: 20px;
    width: auto;
    margin-right: 5px;
    vertical-align: middle;
    opacity: 0.5;
}
.login-form {
    width: 400px;
}
.addPaddingMargin {
    margin-right: 34px;
}
.form-password-row {
    position: relative;
}
.form-password-row > input {
    padding-right: 50px;
}
.form-password-row-eye {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 6px;
    top: 0;
    cursor: pointer;
    opacity: 0.4;
    height: 44px;
    width: 45px;
}
.form-password-row-eye:hover {
    opacity: 1;
}
.form-password-row-eye-show {
    background: url(/images/eye-show.svg) no-repeat center;     
}
.form-password-row-eye-hide {
    background: url(/images/eye-hide.svg) no-repeat center;     
}

.form-title2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #233B4C;
    margin-bottom: 17px;
    text-align: center;
}

#form-forgot-password .form-title {
    margin-bottom: 27px;
}

#form-forgot-password-footer {
    text-align: center;
    margin-top: 69px;
}
#form-forgot-password-footer > span {
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #2C8AE0;
    border-bottom: 1px dotted;
}

#form-forgot-password-info .form-title {
    margin-bottom: 58px;
}
#form-forgot-password-info .form-title2 {
    margin-bottom: 54px;
}
u {
    text-decoration: underline;
}
    
@media only screen and (max-width: 538px)
{
    .form {
        width: 100%;
        margin: 20px;
    }
    .form-title {
        font-size: 20px;
    }
    .form-btn {
        font-size: 20px;
        height: 40px;
    }
    .form {
        padding: 20px;
    }
}
@media only screen and (max-width: 390px)
{
    .form-btn {
        font-size: 18px;
    }
}
@media only screen and (max-width: 340px)
{
    .form-btn {
        font-size: 16px;
    }
}
@media only screen and (max-width: 320px)
{
    .form-btn {
        font-size: 14px;
    }
}
</style>
