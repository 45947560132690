import { createApp } from 'vue'
import Main from '@/Main.vue'
import router from '@/router'
import i18n from '@/i18n.js'

import VueEasyLightbox from 'vue-easy-lightbox'
import titleMixin from './mixins/titleMixin'

import $bus from './event.js';
import { g_config } from './global.js'
import { g_postRequest } from './global.js'
import { g_postRequest2 } from './global.js'
import { g_putRequest } from './global.js'
import { g_getRequest } from './global.js'
import { g_deleteRequest } from './global.js'
import { log } from './global.js'
import { toast } from './toast.js'

const app = createApp(Main);

app.config.globalProperties.$bus            = $bus;
app.config.globalProperties.$config         = g_config;
app.config.globalProperties.$postRequest    = g_postRequest;
app.config.globalProperties.$postRequest2   = g_postRequest2;
app.config.globalProperties.$putRequest     = g_putRequest;
app.config.globalProperties.$getRequest     = g_getRequest;
app.config.globalProperties.$deleteRequest  = g_deleteRequest;
app.config.globalProperties.log             = log;
app.config.globalProperties.$toast          = toast;


app.mixin(titleMixin);

app.use(router);
app.use(i18n);
app.use(VueEasyLightbox);
app.mount('#app');
