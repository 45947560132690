
<template>
    <div>
        <Header :activeLeftMenu="1" />
        <div id="app_root">
            <div id="add_body">
                <div id="table-c">
                    <TableList ref="ui_table" />
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
    import Header from './Header.vue'
    import Footer from './Footer.vue'
    import TableList from './TableList.vue'
    
    export default {
        name: 'Cpanel',
        components: {
            Header, Footer, TableList
        },
        data() {
            return {
                showContent: true,
                
                listResearchesInWork: null,
                listResearches: null,
                
                errorNoUserSpecialisations: false
            };
        },
        watch: {
            '$i18n.locale': function() {
                document.title = this.$t('title_1');
            }
        },
        created() {
            
        },
        mounted() {
            
            document.title = this.$t('title_1');
            
            if( localStorage.token == undefined || localStorage.token.length < 50 ) {
                this.$router.push('/login');
                return;
            }
            
            this.getResearches();
        },
        unmounted() {
            this.$bus.offAll('setLang');
        },
        methods: {
            isNumber (evt) {
                const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
                const keyPressed = evt.key;
                if (!keysAllowed.includes(keyPressed)) {
                    evt.preventDefault()
                }
            },
            getResearches() {
                this.$getRequest(
                        this.$config.urlGetResearches
                     ,  localStorage.token
                )
                .then((data) => {
                    console.log(data);
            
                    if(data.data.Researches) {
                        this.$refs.ui_table.update( data.data.Researches );
                    }
                });
            }
        }
    }
</script>

<style scoped>
    #add_body {
        padding-top: 143px;
    }
    #top-form-c {
        background: #F2F9FF;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-top: 63px;
        padding-bottom: 17px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    #top-form {
        display: flex;
        position: relative;
        width: 1170px;
    }
    .top-form-item {
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        position: relative;
    }
    .top-form-item-label {
        color: #7890A0;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 2px;
    }
    .top-form-item-input {
        width: 190px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #C7D5E1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 14px;
    }
    .top-form-item-input-value {
        flex-grow: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        
    }
    .top-form-item-input-icon {
        width: 16px;
        height: 9.12px;
        background: url(/images/top-form-icon-menu.svg) no-repeat;
    }
    .top-form-item-period-dates {
        width: 190px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #C7D5E1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 14px;
        justify-content: center;
    }
    .top-form-item-input-from,
    .top-form-item-input-to {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: inline-block;
        padding: 5px 2px;
        color: #232323;
    }
    .top-form-item-input-sep {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin: 0 5px;
    }
    .top-form-item-popup-caledar {
        position: absolute;
        top: 70px;
        border-radius: 10px;
        background: #FFF;
        border: 1px solid #C7D5E1;
        padding: 6px 10px;
        display: none;
        transition: opacity 1s ease-out;
        opacity: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }
    .top-form-item-popup-caledar-show {
        opacity: 1;
        display: block;
    }
    .top-form-item-input-text {
        width: 190px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #C7D5E1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 14px;
    }
    .top-form-item-input-field {
        border: none;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #232323;
    }
    .top-form-item-btn {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .top-form-item-btn span {
        cursor: pointer;
        color: #FFFFFF;
        background: #2D87E2;
        border: 1px solid #2D87E2;
        border-radius: 10px;
        width: 130px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    .top-form-item-btn span:hover {
        background: #3073b7;
    }
    .top-form-item-popup-menu {
        position: absolute;
        top: 75px;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 9px 10px 8px 18px;
        min-width: 190px;
    }
    .top-form-item-popup-menu-row {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
        color: #000000;
        margin-bottom: 12px;
        margin-top: 7px;
    }
    .top-form-item-popup-menu-row-select {
        color: #2e92f8;
    }
    .top-form-item-popup-menu .top-form-item-popup-menu-row {
        margin-bottom: 7px;
    }
    #errorNoUserSpecialisations {
        font-weight: 500;
        font-size: 14px;
        line-height: 33px;
        color: #555;
        margin: 50px 0 0 20px;
    }
    #errorNoUserSpecialisations a {
        color: #2D87E2;
    }
    .top-form-item-error {
        border-color: red;
    }
</style>