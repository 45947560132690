<!-- Главный модуль -->

<template>
  <div :class="{ 'night-mode': nightMode, 'bg-night-mode': nightMode }">
    <router-view />
    <loading-div />
    <div id="hover_div"></div>
  </div>
</template>

<script>
import { createBasicStorage } from "./storage/localStorage"
import { getChart } from "./utils/chat"

import LoadingDiv from './components/LoadingDiv.vue'

export default {
  name: 'Main',
  components: {
    LoadingDiv
  },
  data() {
    return {
      nightMode: false,
    }
  },
  created() {
    createBasicStorage();
    getChart();

    if (localStorage.nightMode) {
      this.nightMode = true;
      this.changeBodyClass()
    }

    this.$bus.on('setNightMode', () => {
      this.nightMode = !this.nightMode;
      this.changeBodyClass();
    });
  },
  methods: {
    changeBodyClass() {
      if (this.nightMode) {
        document.body.classList.add('body-night-mode');
        document.documentElement.classList.add('body-night-mode');
      }
      else {
        document.body.classList.remove('body-night-mode');
        document.documentElement.classList.remove('body-night-mode');
      }
    }
  }
}
</script>

<style>
#app {
  color: #2c3e50;
  min-height: 100vh;
}

#app_root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
}

#add_body {
  padding-top: 53px;
  flex-grow: 1;
  width: 1170px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
}

#hover_div {
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  display: none;
  height: 45px;
  background: #f2f9ff;
}

.bg-night-mode {
  background: #202124;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
}
</style>
