<!-- Header -->
<template>
    <div id="header_c" :class="{'night-mode': nightMode}">
        <div id="header">
            <div id="header-logo-c">
                <router-link :to="{ name: 'Home' }" 
                             class="header-logo"
                             :class="{
                                'header-logo-rus': $i18n.locale == 'ru',
                                'header-logo-eng': $i18n.locale != 'ru'
                             }"></router-link>
            </div>
            <div id="header-menu">
                <div id='header-menu-1'>
                    <router-link :to="{ name: 'Home' }" :class="{'active' : active == 1}">{{ $t('research_list') }}</router-link>
                </div>
            </div>
            <div id="header-btn-menu" @click.stop="openRightMenu"></div>
        </div>
        <div v-if="ui_openRightMenu" id="header-right-menu_c" @click.stop="hideRightMenu">
            <div id="header-right-menu" @click.stop="">
                
                <div v-on:click="logoutCpanel" id="header-right-menu-logout">{{ $t('logout') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    //import { getCorrectSuffix } from '../global.js'
    
    export default {
        name: 'Header',
        props: {
            active:          { type: Number, default: 0 },
            activeLeftMenu:  { type: Number, default: 0 },
        },
        data() {
            return {
                ui_openRightMenu: false,
                ui_specialist: '',
                nightMode: false,
            }
        },
        created() {
            if( localStorage.nightMode && localStorage.nightMode !== null ) {
                this.nightMode = true;
            }
            
            this.$bus.on('headerSetSpecialist', (name, stat_info)=> {
                this.ui_specialist = name;
                localStorage.StatInfo = stat_info;
            });
            
            if( localStorage.SpesialictName ) {
                this.ui_specialist = localStorage.SpesialictName;
            }
        },
        mounted() {
            document.addEventListener("click", this.onGlobalClick);
        },
        unmounted() {
            document.removeEventListener("click", this.onGlobalClick);
        },
        methods: {
            onGlobalClick(e) {
                console.log(e);
            },
            logoutCpanel() {
                this.$getRequest(
                      this.$config.urlLogOut
                    , localStorage.token
                )
                .then(() => {
                    localStorage.token = '';
                    this.$router.push('/login');
                })
                .catch(() => {
                    localStorage.token = '';
                    this.$router.push('/login');
                });
            },
            openRightMenu() {
                this.ui_openRightMenu = true;
            },
            hideRightMenu() {
                this.ui_openRightMenu = false;
            },
            setLang(lang) {
                this.$i18n.locale = lang;
                localStorage.lang = lang;
            },
        }
    }
</script>

<style scoped>
    #header_c {
        border-bottom: 1px solid #D1DCE5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    #header {
        height: 53px;
        display: flex;
        align-items: center;
        width: 100%;
        background: #FFF;
        padding: 0 10px;
    }
    #header-city-info {
        margin-top: 6px;
        margin-bottom: 3px;
        font-size: 12px;
    }
    .header-logo {
        height: 25px;
        cursor: pointer;
        width: 122px;
        display: inline-block;
    }
    .header-logo-rus {
        background: url(/images/logo.svg) no-repeat;
    }
    .header-logo-eng {
        background: url(/images/logo_eng.svg) no-repeat;
    }
    #header-menu {
        flex-grow: 1;
        display: flex;
        align-items: center;
        
        margin-left: 50px;
        font-size: 13px;
        line-height: 16px;
        color: #233B4C;
    }
    #header-btn-menu {
        padding: 10px;
        cursor: pointer;
        padding-right: 0;
        display: inline-block;
        width: 30px;
        height: 16x;
        background: url(/images/menu.svg) no-repeat;
    }
    
    #header-menu-1 {
        flex-grow: 1;
    }
    #header-menu-1 > a {
        text-decoration: none;
        color: #AEBAC3;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        
    }
    #header-menu-1 > a.active {
        color: #2D87E2;  
    }
    #header-right-menu_c {
        position: fixed;
        z-index: 1002;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(219,233,244, 0.7);
        display: flex;
        justify-content: flex-end;
    }
    #header-right-menu {
        background: #FFF;
        width: 380px;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;
        z-index: 1003;
        padding-left: 73px;
    }
    #header-right-menu-top-line {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 60px;
    }
    #header-right-menu-top-line-name {
        flex-grow: 1;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #233B4C;
    }
    #header-right-menu-top-line-close-icon {
        cursor: pointer;
        padding: 5px;
        opacity: 0.6;
        width: 22.63px;
        height: 22.63px;
    }
    #header-right-menu-top-line-close-icon:hover {
        opacity: 1;
    }
    #header-right-menu-logout {
        cursor: pointer;
        margin-top: 11px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #233B4C;
    }
    #header-right-menu-stat-info {
        display: flex;
        align-items: center;
        margin-top: 43px;
        margin-bottom: 60px;
    }
    #header-right-menu-stat-info > img {
        display: inline-block;
        margin-right: 7px;
    }
    #header-right-menu-stat-info > span {
        font-size: 14px;
        line-height: 14px;
        color: #000000;
    }
    #events_c {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 144px;
        width: 22px;
        height: 22px;
        color: #FFF;
        position: relative;
    }
    .events_c_hide {
        visibility: hidden;
    }
    #events {
        width: 16px;
        height: 16px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @keyframes crescendo {
        0%   {transform: scale(1.0);}
        50% {transform: scale(1.1);}
        100% {transform: scale(1.3);}
    }
    .no-events {
        background: #D1DCE5;        
        cursor: default;
    }
    .have-events {
        background: #DD4B4B;
        cursor: pointer;
        animation: crescendo 1.5s alternate infinite ease-in;
    }
    .header-right-menu-refs {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .header-right-menu-refs > *.active {
        font-weight: 600;
    }
    .header-right-menu-refs > a,
    .header-right-menu-refs > span
    {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #233B4C;
        margin-bottom: 21px;
        text-decoration: none;
    }
    #header-right-menu-choose-lang {
        margin-bottom: 51px;
    }
    
    #header-right-menu-choose-lang span {
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #2e92f8;
        display: inline-block;
        padding: 5px 5px 5px 0;
        margin-right: 10px;
    }
    .header-right-menu-choose-lang-select {
        color: #233B4C !important;
    }
    
</style>
