<!-- Footer -->

<template>
    <div id="footer">
        <div id="footer_content">
            <span>© {{ $t('footer_1') + YEAR}}</span>
            <span>{{ $t('footer_2') }} +7 962 021-13-02</span>
            <div>
                <a target="_blank" href="https://secondopinions.ru/" id="footer_content_3" class="right">{{ $t('footer_3') }}</a>
            </div>
        </div>   
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                YEAR: new Date().getFullYear()
            }
        }
    }
</script>

<style scoped>
    #footer {
        min-height: 46px;
        position: relative;
        border-top: 1px solid #D1DCE5;
        display: flex;
        align-items: center;
    }
    #footer_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        width: 100%;
    }
    #footer_content span,
    #footer_content a {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: inline-flex;
        align-items: center;
        color: #7890A0;
        text-decoration: none;
        justify-content: center;
        margin: 8px 0;
        flex-basis: 33%;
    }
    #footer_content > *:first-child {
        justify-content: flex-start;
    }
    #footer_content > *:last-child {
        justify-content: flex-end;
    }
    #footer_content a {
        color: #2D87E2;
    }
    
    .night-mode #footer {
        border-top-color: #3C4043;
    }
    .night-mode #footer_content a {
        color: #72B8FF;
    }
    
    @media only screen and (max-width: 1230px)
    {
        #footer_content {
            padding: 0 20px;
            
        }
        #footer_content > * {
            flex-basis: auto;
        }
    }
    @media only screen and (max-width: 860px)
    {
        #footer_content {
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>
